import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { getDocs, addDoc, Timestamp } from "firebase/firestore";
import {AdminCollection, AdminModel} from './firebase-config';

// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';

import { EncryptData } from "./utils/crypt";
// import { updateCollections } from "./utils/localization";


// ----------------------------------------------------------------------

export default function App() {

  const initFirestore = async () => {

    try {
      const snapShot = await getDocs(AdminCollection);
      if (snapShot.size === 0) {
        AdminModel.name = "admin"
        AdminModel.email = "admin@gmail.com"
        AdminModel.password = EncryptData("admin@123")
        AdminModel.status = 1
        AdminModel.is_super = true
        AdminModel.created_at = Timestamp.fromDate(new Date())
        AdminModel.updated_at = Timestamp.fromDate(new Date())
       const newAdmin =  await addDoc(AdminCollection, AdminModel);
        if(!newAdmin)
        {
            console.log("super admin error")
            console.log(newAdmin)
        }
      }
    } catch (error) {

      console.log(error)
    }

  }
  initFirestore();

  return (
    <HelmetProvider>
      <BrowserRouter>
        <ThemeProvider>
          <ScrollToTop />
          <StyledChart />
          <Router />
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}
