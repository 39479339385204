import {Helmet} from 'react-helmet-async';
// @mui
import {getDocs, query,doc,collection} from "firebase/firestore";
import {useEffect, useState} from "react";
import {useTheme} from '@mui/material/styles';
import {Grid, Container, Typography} from '@mui/material';
// sections
import {
    AppWidgetSummary,
} from '../sections/@dashboard/app';
import {Auth} from "../utils/auth";
import {AdminCollection, GroupCollection, UsersCollection} from "../firebase-config";


export default function DashboardAppPage() {
    const [userList, setUserlist] = useState([]);
    const [groupList, setGrouplist] = useState([]);
    const [adminList, setAdminlist] = useState([]);
    const [IsNotFound, setIsNotFound] = useState(true);
    const FetchUsers = async () => {
        const q = query(UsersCollection);
        const querySnapshot = await getDocs(q);

        const userData = [];
        querySnapshot.forEach((doc) => {
            const uData = doc.data()
            uData.id = doc.id;
            userData.push(uData);

        });

        setUserlist(userData);
        if (userData.length > 0) {
            setIsNotFound(false)
        } else {
            setIsNotFound(true)
        }
    }
    const FetchAdmins = async () => {
        const q = query(AdminCollection);
        const querySnapshot = await getDocs(q);

        const adminData = [];
        querySnapshot.forEach((doc) => {
            const aData = doc.data()
            aData.id = doc.id;
            adminData.push(aData);

        });

        setAdminlist(adminData);
        if (adminData.length > 0) {
            setIsNotFound(false)
        } else {
            setIsNotFound(true)
        }
    }

    const FetchGroups = async () => {
        const q = query(GroupCollection);
        const querySnapshot = await getDocs(q);

        const groupData = [];
        const taskPromises = [];

        querySnapshot.forEach((doc) => {
            const gData = doc.data();
            gData.id = doc.id;
            groupData.push(gData);
            taskPromises.push(fetchTasksForGroup(doc.id));
        });

        // Wait for all tasks to be fetched
        const taskCounts = await Promise.all(taskPromises);

        // Update the totalTasks property for each group
        groupData.forEach((group, index) => {
            group.totalTasks = taskCounts[index];
        });

        setGrouplist(groupData);

        if (groupData.length > 0) {
            setIsNotFound(false);
        } else {
            setIsNotFound(true);
        }
    };


    const fetchTasksForGroup = async (groupId) => {
        const groupRef = doc(GroupCollection, groupId);
        const taskCollectionRef = collection(groupRef, 'tasks');
        const taskQuerySnapshot = await getDocs(taskCollectionRef);
        return taskQuerySnapshot.size;
    };

    const theme = useTheme();
    useEffect(() => {
        FetchUsers();
        FetchAdmins();
        FetchGroups();
    }, []);
    return (
        <>
            <Helmet>
                <title> Dashboard | Huistaak </title>
            </Helmet>

            <Container maxWidth="xl">
                <Typography variant="h4" sx={{mb: 5}}>
                    Hi, Welcome back, {Auth().name}
                </Typography>

                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={3}>
                        <AppWidgetSummary title="Users" total={userList.length > 0 ? userList.length : '0'}
                                          icon={'ant-design:android-filled'}/>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <AppWidgetSummary title="Admins" total={adminList.length > 0 ? adminList.length : '0'}
                                          color="info" icon={'ant-design:apple-filled'}/>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <AppWidgetSummary title="Groups" total={groupList.length > 0 ? groupList.length : '0'}
                                          color="warning" icon={'ant-design:windows-filled'}/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <AppWidgetSummary
                            title="Tasks"
                            total={ groupList.length > 0 ? groupList.reduce((total, group) => total + group.totalTasks, 0) : "0"}
                            color="error"
                            icon={'ant-design:bug-filled'}
                        />
                    </Grid>


                </Grid>
            </Container>
        </>
    );
}
