import { DecryptData } from "./crypt";

export const Auth = () => {
    let res = {
        name: "",
        user_id: "",
        email: "",
        is_super: false,
        status: 1,
        permissions:[]
    };
    const result = localStorage.getItem("_ss");
    if (result) {
        console.log(result)
        res = DecryptData(result);
    }
    return res;
}