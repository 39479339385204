import { Helmet } from 'react-helmet-async';

import { useNavigate } from 'react-router-dom';
import { filter } from 'lodash';

import { useEffect, useState } from 'react';
import { orderBy, query, where, getDocs, addDoc, Timestamp, onSnapshot, deleteDoc, doc, updateDoc, collection, getDoc } from "firebase/firestore";

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Grid,

  Button,
  FormControlLabel,

  TableRow,

  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,

  LinearProgress,
  Alert,
  Tooltip,
  Checkbox
} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead } from '../sections/@dashboard/moderators';
import { AdminCollection, AdminModel } from '../firebase-config';


import { Auth } from '../utils/auth';
import { EncryptData } from '../utils/crypt';
import Config from '../utils/config';
import { fDateTime } from '../utils/formatTime';
import Permissions from '../utils/permissions';




// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },

  { id: 'status', label: 'Status', alignRight: false },
  { id: 'added_at', label: 'Added at', alignRight: false },
  { id: 'action', label: 'Actions', alignRight: true },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Moderators() {


  const navigate = useNavigate();


  const [USERLIST, setUSERLIST] = useState([]);

  const [UserPermissions, setUserPermissions] = useState([]);

  const [IsNotFound, setIsNotFound] = useState(true);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [openModal, setOpenModal] = useState(false);

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [delAdminId, setDelAdminId] = useState(0);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [orderByCol, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [createUserErrorMessages, setCreateUserErrorMessages] = useState({ name: "", confirmPass: "", email: "", password: "" });

  const [uFormError, setUFormError] = useState('');
  const [uName, setUName] = useState('');
  const [uId, setUId] = useState('');
  const [uEmail, setUEmail] = useState('');
  const [uPassword, setUPass] = useState('');
  const [uConfirmPassword, setUConfirmPass] = useState('');

  useEffect(() => {
    
    if (Auth().is_super !== true && Auth().permissions.filter(per => {return per === "admins"}).length === 0 ) {
      navigate("/403", { replace: true })
    }
    else {
      FetchUsers();
    }
  }, [navigate]);

  const FetchUsers = async () => {

    const q = query(AdminCollection, where("is_super", "==", false), orderBy("created_at"));
    const querySnapshot = await getDocs(q);

    const userData = [];
    querySnapshot.forEach((doc) => {
      const uData = doc.data()
      uData.id = doc.id;
      userData.push(uData);

    });
    setUSERLIST(userData);
    if (userData.length > 0) {
      setIsNotFound(false)
    }
    else {
      setIsNotFound(true)
    }
  }
  if (Config.fetch_live) {
    const q = query(AdminCollection, where("is_super", "==", false), orderBy("created_at"));
    onSnapshot(q, (snapshot) => {


      const userData = [];
      snapshot.forEach((doc) => {
        const uData = doc.data()
        uData.id = doc.id;
        userData.push(uData);
      });

      setUSERLIST(userData);
      if (userData.length > 0) {
        setIsNotFound(false)
      }
      else {
        setIsNotFound(true)
      }

    });
  }


  const handleRequestSort = (event, property) => {
    const isAsc = orderByCol === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };


  const handleClickOpen = (data) => {
    setUId(data.id)
    if (data.email !== undefined) {
      setUEmail(data.email);
    }
    else {
      setUEmail("");
    }
    if (data.name !== undefined) {
      setUName(data.name);
    }
    else {
      setUName("");
    }
    if(!data.permissions)
    {
      setUserPermissions([])
    }
    else
    {
      setUserPermissions(data.permissions)
    }
    
    setOpenModal(true);
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    setOpenModal(false);
  };
  const handleConfirmClose = () => {
    setConfirmOpen(false)
  }


  const handleModeratorSubmission = async () => {
    setIsSubmitting(true);
    let validated = true;

    const newUserFormErrorMessages = { name: "", confirmPass: "", email: "", password: "", emailFlag: false, passFlag: false, confirmPassFlag: false, nameFlag: false }
    if (!uEmail) {
      validated = false;
      newUserFormErrorMessages.email = "Email is required"
      newUserFormErrorMessages.emailFlag = true
    }
    if (!uName) {
      validated = false;
      newUserFormErrorMessages.name = "Name is required"
      newUserFormErrorMessages.nameFlag = true
    }
    if (uId === "" || uPassword || uConfirmPassword) {

      if (!uPassword) {
        validated = false;
        newUserFormErrorMessages.password = "Password is required"
        newUserFormErrorMessages.passFlag = true
      }
      if (!uConfirmPassword) {
        validated = false;
        newUserFormErrorMessages.confirmPass = "Confirm Password is required"
        newUserFormErrorMessages.confirmPassFlag = true
      }
      if (uConfirmPassword && uPassword) {
        if (uConfirmPassword !== uPassword) {
          validated = false;
          newUserFormErrorMessages.confirmPass = "Confirm password didn't match "
          newUserFormErrorMessages.confirmPassFlag = true
        }
      }
    }
    // if (UserPermissions.length === 0) {
    //   validated = false;
    //   setUFormError("Please Select Atleat One permission")
    // }



    setCreateUserErrorMessages(newUserFormErrorMessages)
    if (validated === false) {
      setIsSubmitting(false);
    }
    else {

      const q = query(AdminCollection, where("email", "==", uEmail));
      const querySnapshot = await getDocs(q);

      AdminModel.name = uName
      AdminModel.permissions = UserPermissions
      AdminModel.email = uEmail
      AdminModel.password = EncryptData(uPassword)
      AdminModel.status = 1
      AdminModel.is_super = false

      AdminModel.updated_at = Timestamp.fromDate(new Date())

      if (querySnapshot.size === 0 && uId === "") {

        AdminModel.name = uName
        AdminModel.email = uEmail
        AdminModel.password = EncryptData(uPassword)
        AdminModel.status = 1
        AdminModel.is_super = false
        AdminModel.created_at = Timestamp.fromDate(new Date())
        AdminModel.updated_at = Timestamp.fromDate(new Date())


        const newDoc = await addDoc(AdminCollection, AdminModel);
        if (newDoc) {
          // FetchUsers()
          setIsSubmitting(false);
          setOpenModal(false);
          setCreateUserErrorMessages({ name: "", confirmPass: "", email: "", password: "", emailFlag: false, passFlag: false, confirmPassFlag: false, nameFlag: false })
          if (!Config.fetch_live) {
            window.location.reload()
          }
        }
        else {
          setIsSubmitting(false);
          setUFormError("Something went wrong")
        }
      }
      else if (uId !== "") {
        let emailExists = false;
        querySnapshot.forEach((doc) => {
          const uData = doc.data()
          uData.id = doc.id;

          if (uId !== doc.id && uData.email === uEmail) {
            emailExists = true;
          }
        });
        if (emailExists) {
          setIsSubmitting(false);
          newUserFormErrorMessages.email = "Email already in use";
          newUserFormErrorMessages.emailFlag = true;
          setCreateUserErrorMessages(newUserFormErrorMessages)
        }
        else {

          const oldDataSnap = await getDoc(doc(AdminCollection, uId));
          if (oldDataSnap.exists()) {
            const oldData = oldDataSnap.data();

            AdminModel.password = oldData.password
            AdminModel.status = oldData.status
            AdminModel.is_super = oldData.is_super
            AdminModel.created_at = oldData.created_at

            AdminModel.name = uName
            AdminModel.email = uEmail
            AdminModel.permissions = UserPermissions
            AdminModel.updated_at = Timestamp.fromDate(new Date())
            if (uPassword) {
              AdminModel.password = EncryptData(uPassword)
            }
            await updateDoc(doc(AdminCollection, uId), AdminModel);
            setIsSubmitting(false);
            setOpenModal(false);
            setCreateUserErrorMessages({ name: "", confirmPass: "", email: "", password: "", emailFlag: false, passFlag: false, confirmPassFlag: false, nameFlag: false })
            if (!Config.fetch_live) {
              window.location.reload()
            }
          }
          else {
            setIsSubmitting(false);
            setUFormError("This moderator  doesn't exists or removed")
            setCreateUserErrorMessages({ name: "", confirmPass: "", email: "", password: "", emailFlag: false, passFlag: false, confirmPassFlag: false, nameFlag: false })
          }
        }
      }
      else {
        setIsSubmitting(false);
        newUserFormErrorMessages.email = "Email already in use";
        newUserFormErrorMessages.emailFlag = true;
        setCreateUserErrorMessages(newUserFormErrorMessages)
      }
    }
  }

  // checkboxes

  const onPermissionBoxHcnages = (e, code) => {
    if (e.target.checked === true) {
      setUserPermissions([...UserPermissions, code])
    }
    else {
      setUserPermissions(UserPermissions.filter(per => { return per !== code }))
    }
  }


  const handleConfirmSubmission = async (_action) => {
    setIsSubmitting(true)
    if (_action === "delete") {
      await deleteDoc(doc(AdminCollection, delAdminId));
    }
    else if (_action === "disable") {
      await updateDoc(doc(AdminCollection, delAdminId), {
        status: 0
      })
    }
    else {
      await updateDoc(doc(AdminCollection, delAdminId), {
        status: 1
      })
    }
    setIsSubmitting(false)
    setConfirmOpen(false)
    if (!Config.fetch_live) {
      window.location.reload()
    }
  }

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderByCol), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  return (
    <>
      <Helmet>
        <title> Admins | Huistaak </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Moderators
          </Typography>
          <Button variant="contained" onClick={() => { handleClickOpen({ id: "" }) }} startIcon={<Iconify icon="eva:plus-fill" />}>
            New Moderator
          </Button>
        </Stack>

        <Card>


          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderByCol}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}

                  onRequestSort={handleRequestSort}

                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {

                    const { id, name, email, status } = row;
                    const CreatedAt = row.created_at.toDate()


                    return (
                      <TableRow hover key={id} tabIndex={-1} >
                        <TableCell align="left">{name}</TableCell>
                        <TableCell align="left">{email}</TableCell>
                        <TableCell align="left">
                          <Label color={(status === 0 && 'error') || 'success'}> {status === 1 ? "Active" : "Disabled"} </Label>
                        </TableCell>
                        <TableCell align="left">{fDateTime(CreatedAt)}</TableCell>
                        <TableCell align="right">

                          {!status &&
                            <Tooltip title="Activate">
                              <IconButton size="large" sx={{ color: 'success.main' }} onClick={() => { setDelAdminId(id); handleConfirmSubmission("activate") }} >
                                <Iconify icon="mdi:user-check" />
                              </IconButton>

                            </Tooltip>
                          }
                          <Tooltip title="Edit">
                            <IconButton size="large" sx={{ color: 'primary.main' }} onClick={() => { handleClickOpen(row) }} >
                              <Iconify icon={'material-symbols:edit'} />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <IconButton size="large" sx={{ color: 'error.main' }} onClick={() => { setDelAdminId(id); setConfirmOpen(true) }} >
                              <Iconify icon={'eva:trash-2-outline'} />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={4} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
                {(!isNotFound && IsNotFound) && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>


                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Dialog
        open={confirmOpen}
        onClose={handleConfirmClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete this moderator"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure to delete this moderator? You can disable it or delete if you want.
          </DialogContentText>
        </DialogContent>
        {isSubmitting ? <LinearProgress style={{ marginBottom: "10px" }} /> :
          <DialogActions>
            <Button onClick={handleConfirmClose} sx={{ color: "secondary.dark" }}>Close</Button>
            <Button onClick={(() => handleConfirmSubmission("disable"))} sx={{ color: "secondary.main" }}>
              Disable
            </Button>
            <Button sx={{ color: "error.main" }} onClick={(() => handleConfirmSubmission("delete"))} >
              Delete
            </Button>
          </DialogActions>}
      </Dialog>



      <Dialog open={openModal} onClose={handleClose}>
        {!uId ? <DialogTitle>Add Moderator</DialogTitle> : <DialogTitle>Update Moderator</DialogTitle>}

        <DialogContent>
          {uId ? <DialogContentText>
            Update your moderators here. They can manage all of your working
          </DialogContentText> : <DialogContentText>
            Add your moderators here. They can manage all of your working
          </DialogContentText>}


          {uFormError && <Alert severity='error'>{uFormError}</Alert>}

          <TextField
            autoFocus
            error={createUserErrorMessages.nameFlag}
            helperText={createUserErrorMessages.name}
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            value={uName}
            variant="standard"
            onChange={(e) => { setUName(e.target.value); setCreateUserErrorMessages({ ...createUserErrorMessages, name: "", nameFlag: false }) }}
          />
          <TextField
            error={createUserErrorMessages.emailFlag}
            helperText={createUserErrorMessages.email}
            margin="dense"
            id="email"
            label="Email Address"
            type="email"
            value={uEmail}
            fullWidth
            variant="standard"
            onChange={(e) => { setUEmail(e.target.value); setCreateUserErrorMessages({ ...createUserErrorMessages, email: "", emailFlag: false }) }}
          />
          {uId && (<Alert severity='info'>Keep empty passwords feild if you don't want to update them</Alert>)}
          <TextField
            error={createUserErrorMessages.passFlag}
            helperText={createUserErrorMessages.password}
            margin="dense"
            id="password"
            label="Password"
            type="password"
            fullWidth
            variant="standard"
            onChange={(e) => { setUPass(e.target.value); setCreateUserErrorMessages({ ...createUserErrorMessages, email: "", passFlag: false }) }}
          />
          <TextField
            error={createUserErrorMessages.confirmPassFlag}
            helperText={createUserErrorMessages.confirmPass}
            margin="dense"
            id="password_confirmed"
            label="Confirm Password"
            type="password"
            fullWidth
            variant="standard"
            onChange={(e) => { setUConfirmPass(e.target.value); setCreateUserErrorMessages({ ...createUserErrorMessages, email: "", confirmPassFlag: false }) }}
          />




        </DialogContent>
        {isSubmitting ? <LinearProgress style={{ marginBottom: "10px" }} /> : <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleModeratorSubmission}>Save</Button>
        </DialogActions>}

      </Dialog>
    </>
  );
}
