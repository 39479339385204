import { Helmet } from 'react-helmet-async';

import { useNavigate } from 'react-router-dom';
import { filter } from 'lodash';

import { useEffect, useState } from 'react';
import { orderBy, query, where, getDocs, addDoc, Timestamp, onSnapshot, deleteDoc, doc, updateDoc, collection, getDoc } from "firebase/firestore";

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Grid,

  Button,
  FormControlLabel,

  TableRow,

  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,

  LinearProgress,
  Alert,
  Tooltip,
  Checkbox
} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead } from '../sections/@dashboard/user';
import {AdminCollection, AdminModel, UserModel, UsersCollection} from '../firebase-config';


import { Auth } from '../utils/auth';
import { EncryptData } from '../utils/crypt';
import Config from '../utils/config';
import { fDateTime } from '../utils/formatTime';
import Permissions from '../utils/permissions';




// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },

    { id: 'status', label: 'Phone Number', alignRight: false },
  { id: 'store_name', label: 'Postal Code', alignRight: false },
  { id: 'action', label: 'Actions', alignRight: true },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Users() {

  const navigate = useNavigate();
  const [USERLIST, setUSERLIST] = useState([]);
  const [IsNotFound, setIsNotFound] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [delAdminId, setDelAdminId] = useState(0);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderByCol, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [uName, setUName] = useState('');
  const [uId, setUId] = useState('');
  const [uEmail, setUEmail] = useState('');
  const [uNumber, setNumber] = useState('');
  const [uPostCode, setPostCode] = useState('');
  const [uFormError, setUFormError] = useState('');
  const [createUserErrorMessages, setCreateUserErrorMessages] = useState({ name: "", number: "", email: "", postalCode: "" });



  const [openModal, setOpenModal] = useState(false);
  useEffect(() => {

    if (Auth().is_super !== true && Auth().permissions.filter(per => { return per === "users" }).length === 0) {
      navigate("/403", { replace: true })
    }
    else {
      FetchUsers();
    }
  }, [navigate]);

  const FetchUsers = async () => {

    const q = query(UsersCollection);
    const querySnapshot = await getDocs(q);

    const userData = [];
    querySnapshot.forEach((doc) => {
      const uData = doc.data()
      uData.id = doc.id;
      userData.push(uData);

    });

    setUSERLIST(userData);
    if (userData.length > 0) {
      setIsNotFound(false)
    }
    else {
      setIsNotFound(true)
    }
  }
  if (Config.fetch_live) {
    const q = query(UsersCollection, orderBy("updated_at"));
    onSnapshot(q, (snapshot) => {


      const userData = [];
      snapshot.forEach((doc) => {
        const uData = doc.data()
        uData.id = doc.id;
        userData.push(uData);
      });

      setUSERLIST(userData);
      if (userData.length > 0) {
        setIsNotFound(false)
      }
      else {
        setIsNotFound(true)
      }

    });
  }


  const handleRequestSort = (event, property) => {
    const isAsc = orderByCol === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };


  const handleConfirmClose = () => {
    setConfirmOpen(false)
  }

  const handleConfirmSubmission = async (_action) => {
    setIsSubmitting(true)
    if (_action === "delete") {
      await deleteDoc(doc(UsersCollection, delAdminId));
    }
    setIsSubmitting(false)
    setConfirmOpen(false)
    if (!Config.fetch_live) {
      window.location.reload()
    }
  }

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderByCol), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;


  const handleClickOpen = (data) => {
    setUId(data.id)
    if (data.email !== undefined) {
      setUEmail(data.email);
    }
    else {
      setUEmail("");
    }
    if (data.displayName !== undefined) {
      setUName(data.displayName);
    }
    else {
      setUName("");
    }

    if (data.phoneNumber !== undefined) {
      setNumber(data.phoneNumber);
    }
    else {
      setNumber("");
    }

    if (data.postalCode !== undefined) {
      setPostCode(data.postalCode);
    }
    else {
      setPostCode("");
    }

    setOpenModal(true);
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    setOpenModal(false);
  };

  const handleModeratorSubmission = async () => {
    setIsSubmitting(true);
    let validated = true;

    const newUserFormErrorMessages = { name: "", number: "", email: "", postCode: "", emailFlag: false, numberFlag: false, confirmPassFlag: false, postCodeFlag: false }
    if (!uEmail) {
      validated = false;
      newUserFormErrorMessages.email = "Email is required"
      newUserFormErrorMessages.emailFlag = true
    }
    if (!uName) {
      validated = false;
      newUserFormErrorMessages.name = "Name is required"
      newUserFormErrorMessages.nameFlag = true
    }
    if (!uNumber) {
      validated = false;
      newUserFormErrorMessages.number = "Phone Number is required"
      newUserFormErrorMessages.numberFlag = true
    }

    if (!uPostCode) {
      validated = false;
      newUserFormErrorMessages.postCode = "Post Code is required"
      newUserFormErrorMessages.postCodeFlag = true
    }


    setCreateUserErrorMessages(newUserFormErrorMessages)
    if (validated === false) {
      setIsSubmitting(false);
    }
    else {
      const q = query(UsersCollection, where("email", "==", uEmail));
      const querySnapshot = await getDocs(q);

      UserModel.displayName= uName
      UserModel.email = uEmail
      UserModel.phoneNumber = uNumber
      UserModel.postalCode = uPostCode
      UserModel.userID = uId

      if (querySnapshot.size === 0 && uId === "") {

        UserModel.displayName = uName
        UserModel.email = uEmail
        UserModel.phoneNumber = uNumber
        UserModel.postalCode = uPostCode
        UserModel.userID = uId


        const newDoc = await addDoc(UsersCollection, UserModel);
        if (newDoc) {

          // FetchUsers()
          setIsSubmitting(false);
          setOpenModal(false);
          setCreateUserErrorMessages({ name: "", number: "", email: "", postalCode: "", emailFlag: false, numberFlag: false, nameFlag: false, postalCodeFlag: false })
          UserModel.userID = newDoc.id;
          await updateDoc(doc(UsersCollection, newDoc.id), UserModel);
          if (!Config.fetch_live) {
            window.location.reload()
          }
        }
        else {
          setIsSubmitting(false);
          setUFormError("Something went wrong")
        }
      }
      else if (uId !== "") {
        let emailExists = false;
        querySnapshot.forEach((doc) => {
          const uData = doc.data()
          uData.id = doc.id;

          if (uId !== doc.id && uData.email === uEmail) {
            emailExists = true;
          }
        });
        if (emailExists) {
          setIsSubmitting(false);
          newUserFormErrorMessages.email = "Email already in use";
          newUserFormErrorMessages.emailFlag = true;
          setCreateUserErrorMessages(newUserFormErrorMessages)
        }
        else {

          const oldDataSnap = await getDoc(doc(UsersCollection, uId));
          if (oldDataSnap.exists()) {
            const oldData = oldDataSnap.data();

            UserModel.displayName = uName
            UserModel.email = uEmail
            UserModel.phoneNumber = uNumber
            UserModel.postalCode = uPostCode
            UserModel.userID = uId

            await updateDoc(doc(UsersCollection, uId), UserModel);
            setIsSubmitting(false);
            setOpenModal(false);
            setCreateUserErrorMessages({ name: "", number: "", email: "", postalCode: "", emailFlag: false, numberFlag: false, nameFlag: false, postalCodeFlag: false })
            if (!Config.fetch_live) {
              window.location.reload()
            }
          }
          else {
            setIsSubmitting(false);
            setUFormError("This moderator  doesn't exists or removed")
            setCreateUserErrorMessages({ name: "", number: "", email: "", postalCode: "", emailFlag: false, numberFlag: false, nameFlag: false, postalCodeFlag: false })
          }
        }
      }
      else {
        setIsSubmitting(false);
        newUserFormErrorMessages.email = "Email already in use";
        newUserFormErrorMessages.emailFlag = true;
        setCreateUserErrorMessages(newUserFormErrorMessages)
      }
    }
  }

  return (
    <>
      <Helmet>
        <title> Users | Huistaak </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Users
          </Typography>
          <Button variant="contained" onClick={() => { handleClickOpen({ id: "" }) }} startIcon={<Iconify icon="eva:plus-fill" />}>
            New User
          </Button>
        </Stack>

        <Card>


          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderByCol}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}

                  onRequestSort={handleRequestSort}

                />
                <TableBody>
                  {filteredUsers.length > 0 && filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {

                    const status = row.is_active;
                    const id = row.id;
                    
                    return <TableRow hover key={row.id} tabIndex={-1} >
                      <TableCell align="left">{row.displayName}</TableCell>
                      <TableCell align="left">{row.email}</TableCell>
                      <TableCell align="left">
                        {row.phoneNumber}
                      </TableCell>
                      <TableCell align="left">{row.postalCode}</TableCell>
                      <TableCell align="right">
                        <Tooltip title="Edit">
                          <IconButton size="large" sx={{ color: 'primary.main' }} onClick={() => { handleClickOpen(row) }} >
                            <Iconify icon={'material-symbols:edit'} />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                          <IconButton size="large" sx={{ color: 'error.main' }} onClick={() => { setDelAdminId(row.id); setConfirmOpen(true) }} >
                            <Iconify icon={'eva:trash-2-outline'} />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  })}
                 


                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={4} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
                {(!isNotFound && IsNotFound) && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>


                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Dialog
        open={confirmOpen}
        onClose={handleConfirmClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete this user"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure to delete this user ?
          </DialogContentText>
        </DialogContent>
        {isSubmitting ? <LinearProgress style={{ marginBottom: "10px" }} /> :
          <DialogActions>
            <Button onClick={handleConfirmClose} sx={{ color: "secondary.dark" }}>Close</Button>
            <Button sx={{ color: "error.main" }} onClick={(() => handleConfirmSubmission("delete"))} >
              Delete
            </Button>
          </DialogActions>}
      </Dialog>


      <Dialog open={openModal} onClose={handleClose}>
        {!uId ? <DialogTitle>Add User</DialogTitle> : <DialogTitle>Update User</DialogTitle>}

        <DialogContent>
          {uId ? <DialogContentText>
            Update your user here.
          </DialogContentText> : <DialogContentText>
            Add your user here. They can manage all of your working
          </DialogContentText>}


          {uFormError && <Alert severity='error'>{uFormError}</Alert>}

          <TextField
              autoFocus
              error={createUserErrorMessages.nameFlag}
              helperText={createUserErrorMessages.name}
              margin="dense"
              id="name"
              label="Name"
              type="text"
              fullWidth
              value={uName}
              variant="standard"
              onChange={(e) => { setUName(e.target.value); setCreateUserErrorMessages({ ...createUserErrorMessages, name: "", nameFlag: false }) }}
          />
          <TextField
              error={createUserErrorMessages.emailFlag}
              helperText={createUserErrorMessages.email}
              margin="dense"
              id="email"
              label="Email Address"
              type="email"
              value={uEmail}
              fullWidth
              variant="standard"
              onChange={(e) => { setUEmail(e.target.value); setCreateUserErrorMessages({ ...createUserErrorMessages, email: "", emailFlag: false }) }}
          />
          <TextField
              error={createUserErrorMessages.numberFlag}
              helperText={createUserErrorMessages.number}
              margin="dense"
              id="number"
              label="Phone Number"
              type="text"
              value={uNumber}
              fullWidth
              variant="standard"
              onChange={(e) => { setNumber(e.target.value); setCreateUserErrorMessages({ ...createUserErrorMessages, number: "", numberFlag: false }) }}
          />
          <TextField
              error={createUserErrorMessages.postalCodeFlag}
              helperText={createUserErrorMessages.postalCode}
              margin="dense"
              id="email"
              label="Postal Code"
              type="text"
              value={uPostCode}
              fullWidth
              variant="standard"
              onChange={(e) => { setPostCode(e.target.value); setCreateUserErrorMessages({ ...createUserErrorMessages, postalCode: "", postalCodeFlag: false }) }}
          />

        </DialogContent>
        {isSubmitting ? <LinearProgress style={{ marginBottom: "10px" }} /> : <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleModeratorSubmission}>Save</Button>
        </DialogActions>}

      </Dialog>

    </>
  );
}
