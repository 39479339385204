
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, } from '@mui/material';
import { LoadingButton,Alert } from '@mui/lab';
import { collection, query, where, getDocs } from "firebase/firestore";
import { DecryptData, EncryptData } from '../../../utils/crypt';

import {db} from '../../../firebase-config';
// components
import Iconify from '../../../components/iconify';





// ----------------------------------------------------------------------

export default function LoginForm() {
  const [errorMessages, setErrorMessages] = useState({ email: "", password: "" });
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errMsg, setErrMsg] = useState("");

  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);




  const handleClick = async () => {
    const newErrorMessages = { email: "", password: "" };
    let err = false;
    if (!email) {
      err = true;
      newErrorMessages.email = "Email is required";
    }

    if (!password) {
      err = true;
      newErrorMessages.password = "Password is required";
    }
    setErrorMessages(newErrorMessages);
    if (err === false) {
      const q = query(collection(db, "admins"), where("email", "==", email));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.size === 0) {
        setErrMsg("Invalid credentials")
      }
      else {
        querySnapshot.forEach((doc) => {
          const docData = doc.data();
          const dbPass = DecryptData(docData.password);
          if (dbPass === password) {
            if (docData.status === 1) {
              setErrMsg("");
              localStorage.setItem("_ss", EncryptData({
                name: docData.name,
                user_id: doc.id,
                email: docData.email,
                is_super: docData.is_super,
                permissions:docData.permissions
              }))
              navigate('/dashboard', { replace: true });
            }
            else {
              setErrMsg("Your account has been blocked by admin")
            }

          }
          else {
            setErrMsg("Invalid credentials")
          }
        });
      }

    }
  };



  return (
    <>
      {errMsg &&(<Alert style={{marginBottom:"10px"}} severity='error'>{errMsg}</Alert>)}
      <Stack spacing={3}>
        <TextField name="email" onChange={(e) => { setEmail(e.target.value) }} label="Email address" />
        {errorMessages.email && <span style={{ color: "red", marginTop: "5px" }}>{errorMessages.email}</span>}
        <TextField
          onChange={(e) => { setPassword(e.target.value) }}
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {errorMessages.password && <span style={{ color: "red", marginTop: "5px" }}>{errorMessages.password}</span>}
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        {/* <Checkbox name="remember" label="Remember me" /> */}
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
        Login
      </LoadingButton>
    </>
  );
}
