import env from "react-dotenv";
import { initializeApp } from "firebase/app";
import { getFirestore, collection } from "firebase/firestore";

const firebaseConfig = {
    apiKey: env.FIREBASE_API,
    authDomain: env.FIREBASE_AUTH_DOMAIN,
    projectId: env.FIREBASE_PROJECT_ID,
    storageBucket: env.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: env.FIREBASE_MESSAGING_ID,
    appId: env.FIREBASE_APP_ID
};


const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);


const AdminCollection = collection(db, "admins")
const UsersCollection = collection(db, "users")
const GroupCollection = collection(db, "groups")

const AdminModel = {
    name: "",
    email: "",
    password: "",
    is_super: false,
    status: 0,
    created_at: null,
    updated_at: null,
}

const UserModel = {
    displayName: "",
    email: "",
    imageUrl: "",
    phoneNumber: "",
    postalCode: "",
    userID: "",
}
export { db, firebaseApp, AdminCollection,UsersCollection,GroupCollection,AdminModel,UserModel};