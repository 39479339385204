import CryptoJS from "crypto-js";
import env from "react-dotenv";


export const EncryptData = (data) => {
    const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data),env.CRYPTOJS_KEY).toString();
    return ciphertext;
    
}

export const DecryptData = (ciphertext) => {
    const bytes = CryptoJS.AES.decrypt(ciphertext,env.CRYPTOJS_KEY);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
} 
