import { useEffect, useState } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { DecryptData } from './utils/crypt';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import Moderators from './pages/Moderators';
import Users from './pages/Users';
import LoginPage from './pages/LoginPage';
import ErrPage from './pages/ErrPage';
import DashboardAppPage from './pages/DashboardAppPage';

// ----------------------------------------------------------------------

// Function to check if the user is authenticated


export default function Router() {


  const [isAuth, setIsAuth] = useState(true);
  const authData = localStorage.getItem('_ss');
  const isAuthenticated = () => {
    if (!authData) {
      setIsAuth(false);
    }
    else {
      const auth = DecryptData(authData);
      if (!auth.email) {
        setIsAuth(false);
      }
      else {
        setIsAuth(true);
      }
    }
  };
  useEffect(() => {
    isAuthenticated();
  }, [authData])
  

  const routes = useRoutes([
    {
      path: '/',
      element: (isAuth) ? <DashboardLayout /> : <Navigate to={'login'} />,
      children: [
        { element: <Navigate to="/dashboard" />, index: true },
        { path: '/dashboard', element: <DashboardAppPage /> },
        { path: 'moderators', element: <Moderators /> },
        { path: 'users', element: <Users /> },
      ],
    },
    {
      path: 'login',
      element: !isAuth ? <LoginPage /> : <Navigate to={'/'} />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard" />, index: true },
        { path: '403', element: <ErrPage messageHeading="Access forbidden" message="You don't have accest to this page"  /> },
        { path: '404', element: <ErrPage messageHeading="Sorry Page not found!" message="Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your spelling." /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
