const Permissions = [
    {
        "code":"admins",
        "name":"Moderator Management"
    },
    {
        "code":"categories",
        "name":"Category Management"
    },
    {
        "code":"countries_cities",
        "name":"Country And City Management"
    },
    {
        "code":"products",
        "name":"Products Management"
    },
    {
        "code":"users",
        "name":"Customer Management"
    }
];

export default  Permissions;
